/* You can add global styles to this file, and also import other style files */
/* @import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css"; */

.spacer { margin:0; padding:0; height:25px; }

:root {
  --inputBorder: #D3D3D3;
   /* --white: #faebd7; */
   --white: rgb(255, 255, 255);
  --darkOrange: rgb(210, 127, 27);
  --darkGreen:  rgb(2, 90, 10);
  --fontWeight: 500;
  --blue: cornflowerblue;
}

body {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 14px !important;
}


.blueText {
  color: var(--blue);
}

h2 {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.acive-link {
  color: rgb(82, 217, 250);
}

.button-blue {
  border-radius: 5px;
  background:rgb(42, 134, 255); 
  
  color: rgb(255, 255, 255);
  border: 1px solid aliceblue; 

  padding: 2px;
  height: 30px;
  
}
.button-blue:hover {
  background:rgb(0, 104, 241); 
  transition: 0.1s;
  border: 2px solid aliceblue; 
  
}

.button-orange {
  border-radius: 5px;
  background:rgb(255, 153, 0) !important;
  
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 205, 88) !important; 

  padding: 2px;
  height: 30px;
  
}
.button-orange:hover {
  background:rgb(174, 99, 0) !important;; 
  transition: 0.1s;
  border: 2px solid rgb(255, 205, 88) !important;; 
}


.button-green {
  border-radius: 5px;
  background:green; 
  
  color: rgb(255, 255, 255);
  border: 1px solid rgb(42, 255, 22); 

  padding: 2px;
  height: 30px;
  
}
.button-green:hover {
  background:darkgreen; 
  transition: 0.1s;
  border: 2px solid rgb(42, 255, 22); 
  
}

.button-red {
  border-radius: 5px;
  background:rgb(255, 42, 42); 
  
  color: rgb(255, 255, 255);
  border: 1px solid aliceblue; 

  padding: 2px;
  height: 30px;
  
}
.button-red:hover {
  background:rgb(215, 0, 0); 
  transition: 0.1s;
  
}


.user-search-box {
  
  padding: 5px;
  height: 30px;
  margin-left: 10px;
  border: 1px solid rgb(216, 216, 216); 
  border-radius: 5px;
  width: 200px;
}

.directory-select {
  
  padding: 5px;
  height: 30px;
  margin-left: 10px;
  border: 1px solid rgb(216, 216, 216); 
  width:120px;
  border-radius: 5px;
}

 label {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
 color:rgb(95, 95, 95)
}
 .searchContainer {
    background:rgb(250, 250, 255);
    border: 1px solid rgb(216, 216, 216); 
    border-radius: 5px;
    padding: 5px;
    height: auto;
    width: 75%;
    font-size: 14px;
  }

 .section-header {
  font-size: 16px;
  font-style:bold;
  color: rgb(82, 217, 250);
 } 
 
/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.smallText {
  font-size: 14px !important; 
}

.localHeader{
  padding: 5px;
  margin: 10px; 
  background-color:aliceblue;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 10px;
}


/* My Form Stuff uses prefxi oc for Our Cloud */
.oc-formInputDiv {
  margin-top: 5px;
  margin-bottom: 5px;
}

.oc-label {
  display: inline-block;
  width: 150px;
}

.oc-input {
  width: 70%;
  border-radius: 5px;
  border: 1px solid var(--inputBorder);
  padding: 5px 10px;
}

.oc-select {
  width: 70%;
  border-radius: 5px;
  border: 1px solid var(--inputBorder);
  padding: 5px 10px;
  height: 30px;
}

.oc-checkbox {
  width: auto;
  /* border-radius: 5px;
  border: 1px solid var(--inputBorder);
  padding: 5px 10px; */
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
 
}

.oc-border {
  border: 1px solid rgb(216, 216, 216); 
    border-radius: 5px;
    padding: 10px;
}

.oc-input-is-invalid {
  border-color: red;
}

.sig-box{
  padding: 10px;
  margin: 10px; 
  
  border: 1px solid; 
  border-color: lightgray;
}

.newFIlterContainer {
  border-radius: 10px;
  /* border-style:solid; */
  border: 1px solid aliceblue;
  /* border-color: aliceblue; */
  flex-wrap: wrap;
  margin: 15px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  /* background-color: beige; */
  padding: 10px;
}
.newFilterForm {
  display: flex;
  margin: 10px;
 
}

.buttonRow {
  padding: 5px;
}

